import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { RootState } from "../store";
import { BASE_API_URL } from "../utils/constants";

axios.defaults.withCredentials = true
export const  axiosBaseQuery = (): BaseQueryFn<{
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  requestHeaders?: AxiosRequestConfig['headers']
}> =>
  async ({ url, method, data, requestHeaders }, { getState }) => {
    const token = (getState() as RootState).appState.authToken

    let headers: AxiosRequestConfig['headers'] = {
      'Content-Type': 'application/json',
      ...requestHeaders
    }

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    try {
      const result = await axios({ url: `${BASE_API_URL}/api${url}`, method, data, headers })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return { error: { status: err.response?.status, data: err.response?.data } };
    }
  }
