import {FunctionComponent} from "react";
import {Message, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import TextInput from "../../../../../components/Forms/TextInput";
import Button from "../../../../../components/Forms/Button";

interface Props {
  onSubmit: (data: ResetPasswordFormData) => Promise<void>
  disabled: boolean
}

export interface ResetPasswordFormData {
  password: string
  password_confirmation: string
}

const ResetPasswordForm: FunctionComponent<Props> = ({onSubmit, disabled}) => {
  const {register, handleSubmit, formState: {errors}, getValues} = useForm<ResetPasswordFormData>();
  const {t} = useTranslation();

  const login = handleSubmit(onSubmit);

  return (
    <form onSubmit={login} className="text-center">
      <TextInput
        label={t('fields.Password')}
        type="password"
        name="password"
        register={register}
        options={{required: true, minLength: 8}}
        error={errors.password}
      />

      <TextInput
        label={t('fields.ConfirmPassword')}
        type="password"
        name="password_confirmation"
        register={register}
        error={errors.password_confirmation}
        options={{
          required: true,
          validate: {
            confirm: (value: string) => {
              return value === getValues('password') || t('forms.validationMessages.confirm') as Message;
            }
          }
        }}
      />
      <Button disabled={disabled}> {t('forms.actions.ResetPassword')} </Button>
    </form>
  )
}

export default ResetPasswordForm
