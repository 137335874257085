import PageLayout from "../../layout/PageLayout";
import {useTranslation} from "react-i18next";
import Button from "../../components/Forms/Button";


const NotFound = () => {
  const {t} = useTranslation();

  return (
    <PageLayout>
      <h3 className="font-bold text-blue-500 pb-4 uppercase">{t('errors.NotFound')}</h3>
      <div className="text-center">
        <Button linkTo="/" size="xl">{t('forms.actions.GoHome')}</Button>
      </div>
    </PageLayout>
  )
}

export default NotFound;
