import {CourseStep} from "../../../types";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Box, Button} from "@mui/material";
import FormInputText from "../../../admin/components/Forms/FormInputText";
import Editor from "../../../components/Forms/Editor";
import {useUploadFileMutation} from "../../../api/admin/api";
import TextInput from "../../../components/Forms/TextInput";


export interface EditStepFormData {
  name: string
  order: number,
  content: string,
  youtubeLink?: string,
  uploads?: FileList,
  attachments?: {
    url: string,
    name: string,
    main: boolean
  }[]
}

interface Props {
  data: CourseStep
  onSubmit: (data: EditStepFormData) => Promise<void>
}

const EditStepForm: FC<Props> = ({data, onSubmit}) => {
  const {t} = useTranslation();
  const {
    handleSubmit,
    control,
    formState: {errors},
    register
  } = useForm<EditStepFormData>({reValidateMode: 'onChange'});
  const [uploadFile] = useUploadFileMutation();

  const submitForm = handleSubmit(async (formData) => {
    formData.attachments = []

    if (formData.uploads) {
      for(let file of Array.from(formData.uploads)) {
        const resp = await uploadFile(file).unwrap()
        formData.attachments.push({url: resp.link, main: false, name: "upload"})
      }
    }

    if (formData.youtubeLink) {
      formData.attachments.push({url: formData.youtubeLink, main: true, name: "video"})
    }
    console.log(formData.attachments)
    await onSubmit({
      name: formData.name,
      order: formData.order,
      content: formData.content,
      attachments: formData.attachments
    })
  })
  return (
    <form onSubmit={submitForm}>
      <Box className="pb-4">
        <FormInputText
          value={data?.name || ''}
          label={t('fields.Name')}
          name="name"
          control={control}
          required={true}
          minLength={3}
          maxLength={255}/>
      </Box>
      <Box className="pb-4">
        <FormInputText
          type="number"
          value={data?.order || ''}
          label={t('fields.Order')}
          name="order"
          control={control}
          required={true}
        />
      </Box>
      <Box className="pb-4">
        <FormInputText
          value={''}
          label={t('fields.YoutubeLink')}
          name="youtubeLink"
          control={control}
          required={false}
          minLength={3}
          maxLength={255}/>
      </Box>
      <Editor control={control} name="content" label={t('fields.Content')} required={true} value={data?.content || ''}/>
      <TextInput
        label={t('fields.Logo')}
        name="uploads"
        type="file"
        multiple
        register={register}
        error={errors.uploads}
        options={{required: false}}
      />
      <Box className="pt-4 text-right">
        <Button type="submit" size="medium" variant="contained">
          {t('forms.actions.Save')}
        </Button>
      </Box>
    </form>
  )
}

export default EditStepForm
