import { createSlice } from '@reduxjs/toolkit';
import {LoginResponse, User} from "../types";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState === null) {
      return {};
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export interface AppSliceState {
  menuType: 'pro' | 'help' |'interested' | 'main';
  authToken: string | null;
  tokenInfo: LoginResponse | null;
  user: User | null
}

const initialState: AppSliceState = {
  menuType: 'main',
  authToken: null,
  tokenInfo: null,
  user: null
};

const persistedState = loadState();
const AppSlice = createSlice({
  name: 'appState',
  initialState: {
    ...initialState,
    ...persistedState,
  },
  reducers: {
    setMenuType(state, action) {
      state.menuType = action.payload;
    },
    setAuthToken(state, action) {
      state.authToken = action.payload.access_token
      state.tokenInfo = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    },
    resetAppState(state, action) {
      state.user =  null
      state.authToken = null
      state.tokenInfo = null
    }
  }
});

export default AppSlice;

export const { setMenuType, setAuthToken, setUser, resetAppState } = AppSlice.actions;
