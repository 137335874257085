import HandCard from "./components/HandCard/HandCard";
import Copyright from "../../components/Copyright";
import {Link} from "react-router-dom";
import React from "react";
import {usePageDetailQuery} from "../../api/pages/api";
import Loader from "../../components/Loader";

const Index = () => {
  const {data: page, isFetching: isLoading} = usePageDetailQuery({slug: 'welcome'})
  return isLoading ? <Loader /> : (
    <div className="">
      <div
        className="bg-gray-blue-500 angled-before before:-skew-y-6 angled-after after:-skew-y-3 after:h-2/6 after:bg-blue-500 after:-bottom-56 before:-bottom-48 before:h-screen">
        <div className="container bg-transparent mx-auto">
          <img src="/logo_bafpro-titul.svg" alt="logo" className="w-60 mx-auto py-16"/>
          <hr className="border-1 border-solid border-blue-500 mx-8"/>
        </div>
      </div>
      <div className="z-10 container relative mx-auto">
        <h2 className="text-blue-500 my-16 text-2xl text-center">Ste odborníkom, ktorý pracuje vo finančnom sektore?</h2>
        <div className="grid xs:grid-cols-1 lg:grid-cols-3 gap-6 mt-14 px-24">
          <HandCard
            image="/ruka-1_tmava.svg"
            selectedOption="pro"
            hoverImage="/ruka-1_svetla.svg"
            text="Áno a chcem sa dozvedieť viac."
          />
          <HandCard
            image="/ruka-2_tmava.svg"
            hoverImage="/ruka-2_svetla.svg"
            selectedOption="interested"
            text="Nie, ale zaujímam sa o&nbsp;financie podľa biblických princípov."
          />
          <HandCard
            image="/ruka-3_tmava.svg"
            hoverImage="/ruka-3_svetla.svg"
            text="Nie, ale hľadám kresťanského finačného poradcu."
            selectedOption="help"
          />
        </div>
        <div className="container mx-auto py-16 px-8 text-justify" dangerouslySetInnerHTML={{__html: page?.content || ''}}>
        </div>
      </div>
      <div
        className="bg-light-blue-100 angled-before before:-skew-y-3 before:h-full before:bg-light-blue-100 before:-z-1 angled-after after:-skew-y-1 after:h-2/6 after:bg-gray-blue-500 after:-bottom-8">
        <div className="container grid md:grid-cols-2 xs:grid-cols mx-auto pb-8 pt-16 px-24">
          <div className="font-bold">
            <p>Bafpro je prvá a najväčšia vzdelávacia platforma v rámci Slovenska predovšetkým pre:</p>
            <ul className="list-disc pl-4 pt-4">
              <li>kresťanských finančných poradcov,</li>
              <li>bankárov,</li>
              <li>poistných agentov,</li>
              <li>investičných agentov</li>
              <li>a iných registrovaných v NBS.</li>
            </ul>
          </div>
          <div className="grid md:grid-cols-2 xs:grid-cols">
            <span className="inline-block align-middle top-1/2 relative text-right pr-8">V spolupráci s:</span>
            <img src="biblia-a-fin_logo.svg" alt="logo biblia a financie" className="w-6/12"/>
          </div>
        </div>
      </div>
      <Copyright/>
      <Link to="/pages/ochrana-osobnych-udajov" className="fixed bottom-10 right-10 cursor-pointer z-9999">
        <img src="/gdpr.png" className="w-18 h-18" alt="gdpr icon b-white"/>
      </Link>
    </div>
  )
}

export default Index
