import {useForgotPasswordMutation} from "../../../api/user/api";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import HeaderImage from "../../../components/HeaderImage";
import Heading from "../../../components/Heading";
import ForgotPasswordForm, {ForgotPasswordFormData} from "./components/ForgotPasswordForm";
import * as Sentry from "@sentry/react";
import {resolveErrorMessage} from "../../../utils/errors";

const ForgotPassword = () => {
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const [postForgotPassword, {isLoading}] = useForgotPasswordMutation();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const submitForgotPassword = async (data: ForgotPasswordFormData) => {
    if (data['g-recaptcha-response'] !== null && !isLoading) {
      try {
        await postForgotPassword(data).unwrap();
        toast.success(t("messages.ForgotPasswordSuccess"))
        navigate('/login')
      } catch (e: any) {
        const error = e.data ? e.data : e
        Sentry.captureException(error);
        toast.error(resolveErrorMessage(error,t('messages.ForgotPasswordError')))
      }
    }
  }

  return (
    <div>
      <HeaderImage imageUrl={imageUrl}/>
      <div className="mx-auto p-4 py-12 max-w-2xl ">
        <Heading>{t('forms.sections.resetPassword.Title')}</Heading>
        <ForgotPasswordForm onSubmit={submitForgotPassword} disabled={isLoading}/>
      </div>
    </div>
  )
}

export default ForgotPassword
