import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {axiosBaseQuery} from "../index";
import {Upload, Paginated, AdminListParams, SuccessResponse, UploadResponse} from '../../types'
import { UploadData, EditUploadData } from "../../admin/components/UploadForm";

interface uploadRequest extends AdminListParams {
    type: string,
    category: string,
    size?: number,
    page?: number
}

export const UploadApi = createApi({
    reducerPath: 'upload',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Uploads'],
    endpoints: (build) => ({
        getUploads: build.query<Paginated<Upload>, uploadRequest>({
            query: ({category, type, search, page=1, size=10}) => ({url: `/uploads/${type}/${category}?page=${page}&size=${size}&search=${search}`, method: 'get'}),
            providesTags: ['Uploads']
        }),
        getUploadDetail: build.query<Upload, { type: string, category: string, id: number }>({
            query: ({category, type, id}) => ({url: `/uploads/${type}/${category}/${id}`, method: 'get'}),
            providesTags: ['Uploads']
        }),
        deleteUpload: build.mutation<SuccessResponse, { type: string, category: string, id: number }>({
            query: ({id, type, category}) => ({url: `/admin/uploads/${type}/${category}/${id}`, method: 'delete'}),
            invalidatesTags: ['Uploads']
        }),
        createUpload: build.mutation<UploadResponse, {data: UploadData, type: string, category: string}>({
            query: ({data, category, type}) => {
              const formData = new FormData();
              formData.append('file', data.file[0])
              formData.append('description', data.description)
              formData.append('order', data.order)
              return ({url: `/admin/uploads/${type}/${category}`, method: 'post', data: formData})
            },
            invalidatesTags: ['Uploads']
        }),
        editUpload: build.mutation<UploadResponse, {data: EditUploadData, type: string, category: string, id: number}>({
            query: ({data, category, type, id}) => {
                return ({url: `/admin/uploads/${type}/${category}/${id}`, method: 'put', data})
            },
            invalidatesTags: ['Uploads']
        })
    })        
})

export const { useGetUploadsQuery, useDeleteUploadMutation, useCreateUploadMutation, useGetUploadDetailQuery, useEditUploadMutation } = UploadApi