import {BellIcon} from "@heroicons/react/outline";
import React, {Fragment} from "react";
import {useMarkAsReadNotificationsMutation, useNotificationsQuery} from "../../../api/user/api";
import {useAuthUser} from "../../../components/UserState/hooks";
import {Popover, Transition} from "@headlessui/react";
import Button from "../../../components/Forms/Button";
import Notification from '../Notification'

const Notifications = () => {
  const user = useAuthUser();

  const {data: notifications, isLoading} = useNotificationsQuery(null, {
    pollingInterval: 60000,
    skip: !user,
  });

  const [markAsRead, {isLoading: isLoadingMark}] = useMarkAsReadNotificationsMutation();

  return user ? (
    <Popover className="relative">
      {({open}) => (
        <>
          <Popover.Button
            disabled={!notifications || notifications?.unread.length === 0}
            className={`
                ${open ? '' : 'text-opacity-90 ring-white'}
                bg-gray-blue-800 p-1 rounded-full relative text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
          >

            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true"/>
            <span className="absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
        <span className={`flex ${notifications && notifications.unread.length === 0 ? 'hidden' : 'inline-block'}`}>
          <span
            className={`${isLoading ? 'bg-blue-500' : 'bg-red-400'} animate-ping absolute inline-flex h-full w-full rounded-full opacity-75`}/>
          <span className={`relative inline-flex rounded-full h-3 w-3 ${isLoading ? 'bg-blue-500' : 'bg-red-400'}`}/>
        </span>
      </span>

          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className="absolute z-10 w-screen max-w-xs px-4 mt-3 transform -translate-x-72 sm:px-0">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 ">
                <div className="relative bg-gray-100 grid grid-cols-2 z-10">
                  <div className="text-left">
                    <p className="text-sm font-semibold py-1 px-2">Latest</p>
                  </div>
                  <div className="text-right">
                    <Button color="link" size="sm" disabled={isLoadingMark} className="text-xs font-light align-sub" onClick={() => markAsRead(null)}>Mark as  read</Button>
                  </div>
                </div>
                <div className="relative grid gap-8 bg-white">
                  {notifications?.unread.map((notification) => (
                    <Notification notification={notification} type="unread"/>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  ) : null;
}

export default Notifications
