

export const resolveErrorMessage = (error: any, defaultMessage: string) => {
  let message = defaultMessage
  if (error.errors) {
    message = Object.keys(error.errors).reduce((v1: any, v2: any) => `${error.errors[v1].join(' ')} ${error.errors[v2].join(' ')}`)
  } else if (error.message) {
    message = error.message
  }

  return message
}

