

const Copyright = () => {
  const today = new Date();

  return (
    <div className="text-center text-gray-400 pt-24 pb-12 text-sm">
      {today.getFullYear()} &#169; Bafpro | Všetky práva vyhradené
    </div>
  )
}

export default Copyright
