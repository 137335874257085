import {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";


interface Props {
  onClose: () => void,
  onConfirm: () => Promise<void> | void
  open: boolean
}

const DeleteConfirmDialog: FC<Props> = ({open, onClose, onConfirm}) => {
  const {t} = useTranslation();

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle id="responsive-dialog-title">
        {t('courses.forms.addChapter.Title')}
      </DialogTitle>
      <DialogContent>
        <div className="py-8 px-4">
          {t('forms.deleteConfirmText')}
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {t('forms.actions.Cancel')}
        </Button>
        <Button onClick={async () => {
          await onConfirm();
          onClose()
        }} autoFocus color="error">
          {t('forms.actions.Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmDialog
