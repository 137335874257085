import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {axiosBaseQuery} from "../index";
import {AdminListParams, Course, Paginated, SuccessResponse} from "../../types";
import {CourseFormData} from "../../admin/components/CourseForm";
import {ChapterFormData} from "../../courses/components/ChapterFormDialog";
import {StepFormData} from "../../courses/components/StepFormDialog";
import {EditStepFormData} from "../../courses/components/EditStepForm";


export const CourseApi = createApi({
    reducerPath: 'courses',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Courses'],
    endpoints: (build) => ({
        courses: build.query<Paginated<Course>, AdminListParams>({
            query: ({search, page = 1, size = 10}) => ({
                url: `/admin/courses?page=${page}&size=${size}&search=${search}`,
                method: 'get'
            }),
            providesTags: ['Courses']
        }),
        courseDetail: build.query<Course, { id: number }>({
            query: ({id}) => ({
                url: `/courses/${id}`,
                method: 'get'
            }),
            providesTags: ['Courses']
        }),
        deleteCourse: build.mutation<SuccessResponse, number>({
            query: (id) => ({url: `/admin/courses/${id}`, method: 'delete'}),
            invalidatesTags: ['Courses']
        }),
        createCourse: build.mutation<SuccessResponse, CourseFormData>({
            query: (data) => ({
                url: `/admin/courses`,
                method: 'post',
                data
            }),
            invalidatesTags: ['Courses']
        }),
        editCourse: build.mutation<SuccessResponse, { data: CourseFormData, id: number }>({
            query: ({data, id}) => ({
                url: `/admin/courses/${id}`,
                method: 'put',
                data
            }),
            invalidatesTags: ['Courses']
        }),
        createCourseChapter: build.mutation<SuccessResponse, { data: ChapterFormData, id: number }>({
            query: ({data, id}) => ({
                url: `/admin/course-chapters`,
                method: 'post',
                data: {...data, course_id: id}
            }),
            invalidatesTags: ['Courses']
        }),
        deleteCourseChapter: build.mutation<SuccessResponse, number>({
            query: (id) => ({
                url: `/admin/course-chapters/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['Courses']
        }),
        editCourseChapter: build.mutation<SuccessResponse, { data: ChapterFormData, id: number }>({
            query: ({data, id}) => ({
                url: `/admin/course-chapters/${id}`,
                method: 'put',
                data: {...data}
            }),
            invalidatesTags: ['Courses']
        }),
        createCourseStep: build.mutation<SuccessResponse, { data: StepFormData, id: number }>({
            query: ({data, id}) => ({
                url: `/admin/course-steps`,
                method: 'post',
                data: {...data, course_chapter_id: id}
            }),
            invalidatesTags: ['Courses']
        }),
        deleteCourseStep: build.mutation<SuccessResponse, number>({
            query: (id) => ({
                url: `/admin/course-steps/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['Courses']
        }),
        deleteCourseStepAttachment: build.mutation<SuccessResponse, { id: number, attachmentId: number }>({
            query: ({id, attachmentId}) => ({
                url: `/admin/course-steps/${id}/${attachmentId}`,
                method: 'delete',
            }),
            invalidatesTags: ['Courses']
        }),
        editCourseStep: build.mutation<SuccessResponse, {
            data: EditStepFormData,
            id: number,
            course_chapter_id: number
        }>({
            query: ({data, id, course_chapter_id}) => ({
                url: `/admin/course-steps/${id}`,
                method: 'put',
                data: {...data, course_chapter_id}
            }),
            invalidatesTags: ['Courses']
        }),
        updateCourseStep: build.mutation<SuccessResponse, {
            courseId: number,
            id: number,
            chapterId: number,
            isComplete: boolean
        }>({
            query: ({isComplete, id, chapterId, courseId}) => ({
                url: `/courses/${courseId}/${chapterId}/${id}`,
                method: 'put',
                data: {isComplete}
            }),
            invalidatesTags: ['Courses']
        }),
    })
})


export const {
    useCoursesQuery,
    useCourseDetailQuery,
    useDeleteCourseMutation,
    useCreateCourseMutation,
    useEditCourseMutation,
    useCreateCourseChapterMutation,
    useDeleteCourseChapterMutation,
    useEditCourseChapterMutation,
    useCreateCourseStepMutation,
    useDeleteCourseStepMutation,
    useEditCourseStepMutation,
    useUpdateCourseStepMutation,
    useDeleteCourseStepAttachmentMutation,
} = CourseApi;
