import {Control} from "react-hook-form/dist/types/form";
import {FieldError} from "react-hook-form/dist/types/errors";
import {FunctionComponent} from "react";
import {Controller} from "react-hook-form";
import {resolveValidationMessage} from "../TextInput";
import {useUploadFileMutation} from "../../../api/admin/api";
import { Editor as TEditor } from '@tinymce/tinymce-react';
import {FormControl, FormHelperText} from "@mui/material";
import * as Sentry from "@sentry/react";


interface Props {
  control: Control<any>
  name: string
  label: string
  required: boolean
  value?: string
  base64Upload?: boolean
  error?: FieldError
}


const Editor: FunctionComponent<Props> = ({control, name, base64Upload = false, label, required, error, value}) => {
  const [uploadFile] = useUploadFileMutation();

  return (
    <div className="pb-4">
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        rules={{required: required, minLength: 3}}
        render={({field, fieldState: {error}}) => {
          return (
            <FormControl error={!!error?.message} fullWidth className={!!error ? 'error-editor' : ''}>
              <label className="text-gray-500">{`${label}${required ? '*' : ''}`}</label>
              <TEditor
                value={field.value}
                onEditorChange={field.onChange}
                apiKey="l1vv22obp09u6lv98hvp8fksoyoda2mo65dtmzg1r13bodzi"
                init={{
                  height: 500,
                  menubar: false,
                  language: 'sk',
                  relative_urls: false,
                  fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
                  file_picker_types: 'file image media',
                  content_style: `
                    hr {
                      margin: 0 auto;
                      border-top-width: 1px;
                      border-style: solid;
                      width: 50%
                      --tw-border-opacity: 1;
                      border-color: rgb(7 163 224 / var(--tw-border-opacity));
                    }
                    
                    .btn {display: inline-block;
    border-radius: 0.25rem;
    font-weight: 600;
    text-transform: uppercase;}
    .btn-md {    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;} .primary {}
                  `,
                  images_upload_handler(blobInfo, success, failure) {
                    if (base64Upload) {
                      success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
                    } else {
                      uploadFile(blobInfo.blob())
                        .then((res) => {
                          if ('data' in res) {
                            success(res.data.link);
                          } else {
                            Sentry.captureException(res.error);
                            failure(`Upload failed`);
                          }
                        })
                        .catch((err) => {
                          Sentry.captureException(err);
                          failure(`HTTP Error: ${err.message}`);
                        });
                    }
                  },
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor hr',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect fontsizeselect forecolor | ' +
                    'bold italic underline strikethrough hr backcolor  | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'table | link image | removeformat code fullscreen | help',
                }}
              />
              {error ?
                <FormHelperText>{resolveValidationMessage(error, label, {})}</FormHelperText> : null}
            </FormControl>
          )
        }}
      />
      {error ?
        (<p className="text-red-500 text-xs italic mt-1">{resolveValidationMessage(error, label, {})}</p>) : null
      }
    </div>)
}

export default Editor
