import Heading from "../../../components/Heading";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import PageLayout from "../../../layout/PageLayout";
import {useSubscribeEventMutation, useUserEventsQuery} from "../../../api/events/api";
import {useAuthUser} from "../../../components/UserState/hooks";
import Loader from "../../../components/Loader";
import Button from "../../../components/Forms/Button";
import {CheckCircleIcon, ExternalLinkIcon} from "@heroicons/react/solid";
import {CalendarIcon} from "@heroicons/react/outline";
import {format, parseISO} from "date-fns";
import * as Sentry from "@sentry/react";
import {resolveErrorMessage} from "../../../utils/errors";


const UserCourses = () => {
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const {t} = useTranslation();
  const user = useAuthUser();
  const [subscribe, {isLoading: isLoadingSubscribe}] = useSubscribeEventMutation();
  const {data: events, isFetching: isLoadingEvents} = useUserEventsQuery();
  const navigate = useNavigate();

  const handleSubscribeToEvent = async (eventId: number) => {
    if (!isLoadingSubscribe) {
      try {
        await subscribe({id: eventId, email: user!.email}).unwrap();
        toast.success(t("messages.UserCourseSubscribe"))
        navigate('/login')
      } catch (e: any) {
        const error = e.data ? e.data : e
        Sentry.captureException(error);
        toast.error(resolveErrorMessage(error, t("messages.UserCourseSubscribe")))
      }
    }
  }

  return (
    <PageLayout imageUrl={imageUrl}>
      <div className="p-4 py-12 max-w-2xl">
        <Heading>{t('forms.sections.userCourses.Title')}</Heading>
        <Loader isLoading={isLoadingEvents}/>
        <ul className="list-none pl-0">
          {
            events && events.map((event) => (
              <li>
                <div className="flex py-4">
                  <div className="flex-grow">
                    <h3 className="text-lg font-semibold text-blue-500">{event.name}</h3>
                    <div className="pt-1 pb-4"><CalendarIcon className="w-6 h-6 text-blue-500 inline-block"/> <span
                      className="inline-block align-sub">{format(parseISO(event.start_date), 'dd.LL.yyyy HH:mm')}</span>
                    </div>
                    <span dangerouslySetInnerHTML={{__html: event.description}}/>
                  </div>
                  <div className={`pl-4 grid ${event.course && event.isUserRegistered  ? 'grid-cols-2' : ''} content-end`}>
                    {event.isUserRegistered && event.course && (
                        <div>
                          <a href={`/course/${event.course.id}`}>
                            <span className="w-8 h-8 text-gray-blue-700 inline-block">
                              <ExternalLinkIcon/>
                            </span>
                          </a>
                        </div>
                    )}
                    {event.isUserRegistered ? (
                      <div className="text-green-500">
                        <span className="w-8 h-8 text-green-500 inline-block">
                          <CheckCircleIcon/>
                        </span>
                        <span>
                         Prihlasený/a
                        </span>
                      </div>
                    ) : (<Button
                      color="primary"
                      disabled={isLoadingSubscribe}
                      onClick={() => handleSubscribeToEvent(event.id)}>
                      {t('forms.actions.Login')}
                    </Button>)}
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    </PageLayout>
  )
}

export default UserCourses
