import {AnnotationIcon} from "@heroicons/react/outline";
import React, {FunctionComponent} from "react";
import {Notification as NotificationType} from "../../../types";

const resolveNotificationPath = (notification: NotificationType) => {
  switch (notification.type) {
    case "App\\Notifications\\DataAfterRegistrationReady":
      return "/user/profile"
    default:
      return "/"
  }
}

interface Props {
  notification: NotificationType
  type: 'read' | 'unread'
}

// TODO: check if we need the read one
const Notification: FunctionComponent<Props> = ({notification, type}) => {
  return (
    <a
      key={notification.id}
      href={resolveNotificationPath(notification)}
      className={`bg-sky-100 flex items-center p-7 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50`}
    >
      <div
        className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-8 sm:w-8">
        <AnnotationIcon className="w-full h-auto text-blue-500" />
      </div>
      <div className="ml-4">
        <p className="text-xs font-medium text-gray-900">
          {notification.data.content}
        </p>
      </div>
    </a>
  )
}

export default Notification
