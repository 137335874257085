import {useTranslation} from "react-i18next";
import Heading from "../../../components/Heading";
import PageLayout from "../../../layout/PageLayout";
import EditProfileForm from "./components/EditProfileForm";
import {useEditProfileMutation} from "../../../api/user/api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useUploadFileMutation} from "../../../api/admin/api";
import {EditProfileFormData} from "../../../types";
import * as Sentry from "@sentry/react";
import {resolveErrorMessage} from "../../../utils/errors";

const EditProfile = () => {
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const {t} = useTranslation();
  const [editProfile, {isLoading}] = useEditProfileMutation();
  const [uploadImage, {isLoading: isLoadingImage}] = useUploadFileMutation();
  const navigate = useNavigate();

  const handleSubmitEditProfile = async (data: EditProfileFormData) => {
    if (!isLoading) {
      try {
        let logoResp = null
        let profileImageResp = null
        if (data.profileImage && data.profileImage.length > 0) profileImageResp = await uploadImage(data.profileImage[0]).unwrap()
        if (data.logo && data.logo?.length > 0) logoResp = await uploadImage(data.logo[0]).unwrap()
        await editProfile({...data, profileImage: profileImageResp || null, logo: logoResp}).unwrap();
        toast.success(t("messages.EditProfileSuccess"))
        navigate('/user/profile')
      } catch (e: any) {
        const error = e.data ? e.data : e
        Sentry.captureException(error);
        toast.error(resolveErrorMessage(error, t("messages.EditProfileError")))
      }
    }
  }

  return (
    <PageLayout imageUrl={imageUrl}>
      <div className="mx-auto p-4 py-12 max-w-2xl ">
        <Heading>{t('profile.Profile')}</Heading>
        <EditProfileForm onSubmit={handleSubmitEditProfile} disabled={isLoading || isLoadingImage} />
      </div>
    </PageLayout>
  )
}

export default EditProfile
