import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FunctionComponent} from "react";
import {FieldError} from "react-hook-form/dist/types/errors";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import './textInput.css';
import i18n from "i18next";
import {DISPLAY_PATTERNS} from "../../../utils/constants";

interface Props {
  label: string
  name: string
  placeholder?: string
  register: UseFormRegister<any>
  options: RegisterOptions
  defaultValue?: string
  multiple?: boolean
  disabled?: boolean
  error?: FieldError
  type?: 'text' | 'password' | 'email' | 'tel' | 'file' | 'image' | 'number'
}

export const resolveValidationMessage = (error: FieldError, label: string, options: RegisterOptions) => {
  //@ts-ignore
  let value = options[error.type]
  if (error.message) return error.message
  // @ts-ignore
  if (error.type === "pattern") value = DISPLAY_PATTERNS[options[error.type]] ?  DISPLAY_PATTERNS[options[error.type]] : value

  return i18n.t(`forms.validationMessages.${error.type}`, {field: label, value})
}

const TextInput: FunctionComponent<Props> = (
  {
    register,
    options,
    name,
    label,
    placeholder,
    error,
    defaultValue,
    disabled= false,
    multiple= false,
    type = "text"
  }) => {

  return (
    <div>
      <label className="text-gray-600 font-medium">{label} {options.required ? '*' : ''}</label>
      <div className="py-4 pr-4">
        <input
          multiple={multiple}
          disabled={disabled}
          className={`border border-gray-300 py-2 px-3 w-full rounded focus:ring-blue-300 focus:outline-1 disabled:bg-gray-200 disabled:cursor-not-allowed ${error ? 'border-red-300' : ''}`}
          {...register(name, options)}
          defaultValue={defaultValue}
          type={type}
          placeholder={placeholder}
        />
        {error ?
          (<p className="text-red-500 text-xs italic mt-1">{resolveValidationMessage(error, label, options)}</p>) : null
        }
      </div>
    </div>
  )
}


export default TextInput
