import {Link} from "react-router-dom";
import React from "react";
import {useAuthUser} from "../UserState/hooks";


const BiblicalQuote =  () => {
  const user = useAuthUser();

  return (
    <div className="bg-slate-800 p-5 relative z-10">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 text-white mx-auto flex flex-wrap">
        <span className="text-center inline-block align-super w-full lg:w-auto flex-none pr-4 pl-6">
          <p className="pl-1">Ak sa však niekomu z vás nedostáva múdrosti, nech prosí Boha, ktorý dáva štedro a bez
            výčitiek,
            a
            dostane ju. (Jak 1:5)</p>
        </span>
        <span className="lg:text-right text-center inline-block pt-4 md:pt-0 lg:flex-auto w-full md:w-32 ">
          {user ? null : <Link to="/login" className="align-super">Prihlásiť sa</Link>}
        </span>

      </div>
    </div>
  )
}

export default BiblicalQuote
