import React, {FunctionComponent} from "react";
import {Menu} from "@headlessui/react";
import {Link} from "react-router-dom";
import {classNames} from "../../AppLayout";

interface Props {
  linkTo?: string
  onClick?: () => void
}

const UserMenuLink: FunctionComponent<Props> = ({children, linkTo, onClick}) => {
  return (
    <Menu.Item>
      {
        onClick ? (
          <button
            onClick={onClick}
            className='hover:bg-blue-100 w-full text-left font-medium block px-4 py-2 text-sm text-gray-700 first:rounded-t last:rounded-b'
          >
            {children}
          </button>
        ) : ({active}) => (
          <Link
            to={linkTo || ''}
            className={classNames(active ? 'bg-blue-100' : '', 'font-medium block px-4 py-2 text-sm text-gray-700 first:rounded-t last:rounded-b')}
          >
            {children}
          </Link>
        )
      }
    </Menu.Item>
  )
}

export default UserMenuLink
