import {FC} from "react";
import {RootState, useAppSelector} from "../../store";
import {useAuthUser} from "./hooks";
import Loader from "../../components/Loader";


const UserState: FC = ({children}) => {
  const accessToken = useAppSelector(
    (state: RootState) => state.appState.authToken
  );
  const user = useAuthUser();

  if (!user && accessToken) {
    return (
      <Loader isLoading={true}/>
    );
  }

  return children as React.ReactElement;
};

export default UserState;
