import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {axiosBaseQuery} from "../index";
import {
  AdminUsersListParams,
  EditProfileRequest,
  LoginResponse,
  NotificationsResponse, Paginated,
  ResetPasswordData,
  SuccessResponse,
  User,
  VerifyUserData
} from "../../types";
import {RegistrationFormData} from "../../pages/Auth/Registration";
import {format} from "date-fns";
import {
  ForgotPasswordFormData
} from "../../pages/Auth/ForgotPassword/components/ForgotPasswordForm";
import {ChangePasswordFormData} from "../../pages/User/ChangePassword/ChangePasswordForm";
import {ConcatFormData} from "../../pages/Contact/types";
import {UserFormData} from "../../admin/components/PageForm";
import {AdminUserEditData} from "../../admin/pages/Users/Edit/components/EditUserForm";

export interface LoginData {
  email: string
  password: string
  'g-recaptcha-response': string | null
}

export const UserApi = createApi({
  reducerPath: 'user',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['User', 'Notification', 'Users'],
  endpoints: (build) => ({
    getUser: build.query<User, null>({
      query: () => ({url: `/user`, method: 'get'}),
      providesTags: ['User']
    }),
    adminUser: build.query<User, number>({
      query: (id) => ({url: `/admin/users/${id}`, method: 'get'}),
      providesTags: ['User']
    }),
    notifications: build.query<NotificationsResponse, null>({
      query: () => ({url: `/notifications`, method: 'get'}),
      providesTags: ['Notification']
    }),
    adminUsers: build.query<Paginated<User>, AdminUsersListParams>({
      query: ({search, page = 1, size = 10}) => ({
        url: `/admin/users?page=${page}&size=${size}&search=${search}`,
        method: 'get'
      }),
      providesTags: ['Users']
    }),
    markAsReadNotifications: build.mutation<SuccessResponse, null>({
      query: () => ({url: `/notifications`, method: 'put'}),
      invalidatesTags: ['Notification']
    }),
    login: build.mutation<LoginResponse, LoginData>({
      query: (data) => ({url: '/auth/login', method: 'post', data})
    }),
    changePassword: build.mutation<SuccessResponse, ChangePasswordFormData>({
      query: (data) => ({url: '/change-password', method: 'put', data})
    }),
    editProfile: build.mutation<SuccessResponse, EditProfileRequest>({
      query: (data) => ({url: '/user', method: 'put', data}),
      invalidatesTags: ['User']
    }),
    verifyUser: build.mutation<SuccessResponse, VerifyUserData>({
      query: ({token, hash, authToken}) => ({
        url: `/email/verify/${token}/${hash}`, method: 'post', requestHeaders: {
          'Authorization': `Bearer ${authToken}`
        }
      })
    }),
    forgotPassword: build.mutation<SuccessResponse, ForgotPasswordFormData>({
      query: (data) => ({url: '/auth/forgot-password', method: 'post', data})
    }),
    resetPassword: build.mutation<SuccessResponse, ResetPasswordData>({
      query: (data) => ({url: '/auth/reset-password', method: 'post', data})
    }),
    resendVerificationEmail: build.mutation<SuccessResponse, string>({
      query: (token) => ({
        url: '/email/verify/resend', method: 'post', requestHeaders: {
          'Authorization': `Bearer ${token}`
        }
      })
    }),
    registerUser: build.mutation<SuccessResponse, RegistrationFormData>({
      query: (data) => {
        return {
          url: '/auth/register',
          method: 'post',
          data: {...data, birthDate: format(data.birthDate, 'dd.LL.yyyy').toString()}
        }
      },
    }),
    sendContactMessage: build.mutation<SuccessResponse, ConcatFormData>({
      query: (data) => ({url: '/contact', method: 'post', data})
    }),
    editUser: build.mutation<SuccessResponse, { data: UserFormData, id: number }>({
      query: ({data, id}) => ({url: `/admin/users/${id}`, method: 'put', data}),
      invalidatesTags: ['Users']
    }),
    editAdminUser: build.mutation<User, {data: AdminUserEditData, id: number}>({
      query: ({data, id}) => ({url: `/admin/users/${id}`, method: 'put', data}),
      invalidatesTags: ['Users', 'User']
    }),
    deleteUser: build.mutation<SuccessResponse, { id: number }>({
      query: ({id}) => ({url: `/admin/users/${id}`, method: 'delete'}),
      invalidatesTags: ['Users']
    }),
  })
});

export const {
  useGetUserQuery,
  useNotificationsQuery,
  useAdminUsersQuery,
  useAdminUserQuery,
  useEditAdminUserMutation,
  useLoginMutation,
  useForgotPasswordMutation,
  useVerifyUserMutation,
  useResetPasswordMutation,
  useMarkAsReadNotificationsMutation,
  useResendVerificationEmailMutation,
  useRegisterUserMutation,
  useChangePasswordMutation,
  useEditProfileMutation,
  useSendContactMessageMutation,
  useDeleteUserMutation
} = UserApi;
