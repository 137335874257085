import {FunctionComponent} from "react";

interface Props {
  isLoading?: boolean
}

const Loader: FunctionComponent<Props> = ({isLoading = true}) => {
  return isLoading ? (
    <div className="absolute top-0 left-0 right-0 bottom-0 bg-white z-9999">
      <div className="text-center h-full flex flex-col justify-center content-center">
        <div>
          <img src="/logo.jpg" alt="loader" data-testid="loader" className="mx-auto animate-pulse w-auto h-32"/>
        </div>
      </div>
    </div>
  ) : null
}

export default Loader
