import { useTranslation } from "react-i18next";
import { useUserEventsQuery } from "../../api/events/api";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../layout/PageLayout";
import Heading from "../../components/Heading";
import Loader from "../../components/Loader";
import { CalendarIcon } from "@heroicons/react/outline";
import { format, parseISO } from "date-fns";
import Button from "../../components/Forms/Button";

const Events = () => {
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const { t } = useTranslation();
  const { data: events, isFetching: isLoadingEvents } = useUserEventsQuery();
  const navigate = useNavigate();

  return (
    <PageLayout imageUrl={imageUrl}>
      <div className="p-4 py-12 max-w-2xl">
        <Heading>{t('forms.sections.userCourses.Title')}</Heading>
        <Loader isLoading={isLoadingEvents} />
        <ul className="list-none pl-0">
          {
            events && events.map((event) => (
              <li key={`events-${event.id}`}>
                <div className="flex py-4">
                  <div className="flex-grow">
                    <h3 className="text-lg font-semibold text-blue-500">{event.name}</h3>
                    <div className="pt-1 pb-4"><CalendarIcon className="w-6 h-6 text-blue-500 inline-block" /> <span
                      className="inline-block align-sub">{format(parseISO(event.start_date), 'dd.LL.yyyy HH:mm')}</span>
                    </div>
                    <span dangerouslySetInnerHTML={{ __html: event.description }} />
                  </div>
                  <div className="pl-4 grid content-end">
                    <Button
                      color="primary"
                      onClick={() => navigate(`/events/${event.id}`)}>
                      {t('forms.actions.Login')}
                    </Button>
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    </PageLayout>
  )
}

export default Events
