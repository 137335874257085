import {FunctionComponent, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import TextInput from "../../../../../components/Forms/TextInput";
import {useNavigate} from "react-router-dom";
import Button from "../../../../../components/Forms/Button";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA_KEY} from "../../../../../utils/constants";

interface Props {
  onSubmit: (data: ForgotPasswordFormData) => Promise<void>
  disabled: boolean
}

export interface ForgotPasswordFormData {
  email: string
  'g-recaptcha-response': string | null
}

const ForgotPasswordForm: FunctionComponent<Props> = ({onSubmit, disabled}) => {
  const {register, handleSubmit, formState: {errors}} = useForm<ForgotPasswordFormData>();
  const [recaptchaToken, setRecaptchaToken] = useState<null | string>(null);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const login = handleSubmit((data,) => {
    onSubmit({
      ...data,
      'g-recaptcha-response': recaptchaToken
    })
  });

  return (
    <form onSubmit={login} className="text-center">
      <TextInput
        label={t('fields.Email')}
        register={register}
        name="email"
        options={{required: true}}
        error={errors.email}
      />

      <div className="pt-4 pb-8">
        <ReCAPTCHA
          sitekey={RECAPTCHA_KEY}
          onChange={setRecaptchaToken}
        />
      </div>
      <Button disabled={disabled}> {t('forms.actions.SendForgotPassword')} </Button>
      <Button className="ml-4" onClick={() => navigate('/login')} color="link"> {t('forms.actions.Back')} </Button>
    </form>
  )
}

export default ForgotPasswordForm
