import React, {FunctionComponent, Suspense, useEffect} from 'react';
import './App.css';
import {Routes, Route, useLocation, useNavigate, Navigate} from "react-router-dom";
import Loader from "./components/Loader";
import Home from "./pages/Home";
import AppLayout from "./layout/AppLayout";
import Welcome from "./pages/Welcome";
import {useAppSelector} from "./store";
import Login from "./pages/Auth/Login";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import Registration from "./pages/Auth/Registration";
import Page from "./pages/Page";
import RequireAuth from "./routes/RequireAuth";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import BiblicalQuote from "./components/BiblicalQuote";
import ChangePassword from "./pages/User/ChangePassword";
import Profile from "./pages/User/Profile";
import EditProfile from "./pages/User/EditProfile";
import Contact from "./pages/Contact";
import NotFound from "./pages/Error/NotFound";
import UserCourses from "./pages/User/UserCourses";
import CookieConsent, {Cookies, getCookieConsentValue} from "react-cookie-consent";
import {initGA} from "./utils/ga";
import {G_ANANLYTICS_KEY} from "./utils/constants";
import Course from "./courses/pages/Course";
import Events from "./pages/Events";
import EventDetail from "./pages/Events/Detail";

const ALLOWED_ROUTES = ['/welcome', '/forgot-password', '/login']
const AdminPages = React.lazy(() => import('./admin/pages/Pages'));
const AdminCreatePage = React.lazy(() => import('./admin/pages/Pages/Create'));
const AdminEditPage = React.lazy(() => import('./admin/pages/Pages/Edit'));
const AdminEvents = React.lazy(() => import('./admin/pages/Events'));
const AdminCreateEvent = React.lazy(() => import('./admin/pages/Events/Create'));
const AdminEditEvent = React.lazy(() => import('./admin/pages/Events/Edit'));
const AdminUsers = React.lazy(() => import('./admin/pages/Users'));
const AdminEditUser = React.lazy(() => import('./admin/pages/Users/Edit'));
const AdminCourses = React.lazy(() => import('./admin/pages/Courses'));
const AdminCreateCourse = React.lazy(() => import('./admin/pages/Courses/Create'));
const AdminEditCourse = React.lazy(() => import('./admin/pages/Courses/Edit'));
const AdminSidebarImages = React.lazy(() => import('./admin/pages/SidebarImages'));
const AdminCreateSidebarImages = React.lazy(() => import('./admin/pages/SidebarImages/Create'));
const AdminEditSidebarImages = React.lazy(() => import('./admin/pages/SidebarImages/Edit'));
const AdminGuests = React.lazy(() => import('./admin/pages/Guests'))
const AdminEditGuest = React.lazy(() => import('./admin/pages/Guests/Edit'))
const AdminCreateGuest = React.lazy(() => import('./admin/pages/Guests/Create'))

const App: FunctionComponent = () => {
    const menuType = useAppSelector((state) => state.appState.menuType);
    const navigate = useNavigate();
    const location = useLocation();

    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
    };

    const handleAcceptCookie = () => {
        const isConsent = getCookieConsentValue();
        initGA(G_ANANLYTICS_KEY, isConsent === "true");
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);

    useEffect(() => {
        if (menuType === "main" && ALLOWED_ROUTES.indexOf(location.pathname) === -1 && !location.pathname.startsWith('/reset-password') && !location.pathname.startsWith('/admin') && !location.pathname.startsWith('/events') && !location.pathname.startsWith('/pages/ochrana-osobnych-udajov')) {
            navigate('/welcome', {replace: true})
        }
    }, [menuType, location, navigate])

    return (
        <Suspense fallback={<Loader/>}>
            <CookieConsent
                location="bottom"
                buttonText="Súhlasím"
                declineButtonText="Nesúhlasím"
                onAccept={() => handleAcceptCookie()}
                onDecline={() => handleDeclineCookie()}
                acceptOnOverlayClick
                enableDeclineButton
                style={{background: "#1E293B"}}
                buttonStyle={{color: "white", background: "#04A3E0"}}
            >
                Tento web používa k poskytovaniu služieb a analýze návštevnosti súbory cookie. Používaním tohto webu s
                tým súhlasíte. <a href="https://policies.google.com/technologies/cookies?hl=sk" target="_blank"
                                  rel="noreferrer">Viac informácií</a>
            </CookieConsent>
            <Routes>

                <Route path="/welcome" element={(
                    <>
                        <BiblicalQuote/>
                        <Welcome/>
                    </>
                )}/>
                <Route element={<AppLayout/>}>
                    <Route path="/" element={<Home/>}/>
                    <Route path="kontakt" element={<Contact/>}/>
                    <Route
                        path="user"
                        element={<RequireAuth/>}
                    >
                        <Route path="profile" element={(
                            <Profile/>
                        )}/>
                        <Route path="change-password" element={<ChangePassword/>}/>
                        <Route path="profile/edit" element={<EditProfile/>}/>
                        <Route path="courses" element={<UserCourses/>}/>
                    </Route>
                    <Route element={<RequireAuth onlyPublic={true}/>}>
                        <Route path="login" element={<Login/>}/>
                        <Route path="forgot-password" element={<ForgotPassword/>}/>
                        <Route path="reset-password/:token/:email" element={<ResetPassword/>}/>
                        <Route path="registration" element={<Registration/>}/>
                    </Route>
                    <Route path="/pages/:parent/:slug" element={<Page/>}/>
                    <Route path="/pages/:slug" element={<Page/>}/>
                </Route>

                <Route path="admin" element={<RequireAuth onlyAdmin={true}/>}>
                    <Route index element={<Navigate to="/admin/pages" replace/>}/>
                    <Route path="pages/:slug" element={<AdminEditPage/>}/>
                    <Route path="pages/create" element={<AdminCreatePage/>}/>
                    <Route path="pages" element={<AdminPages/>}/>

                    <Route path="sidebar-images" element={<AdminSidebarImages/>}/>
                    <Route path="sidebar-images/create" element={<AdminCreateSidebarImages/>}/>
                    <Route path="sidebar-images/:id" element={<AdminEditSidebarImages/>}/>

                    <Route path="users" element={<AdminUsers/>}/>
                    <Route path="users/:id" element={<AdminEditUser/>}/>

                    <Route path="events" element={<AdminEvents/>}/>
                    <Route path="events/:id" element={<AdminEditEvent/>}/>
                    <Route path="events/create" element={<AdminCreateEvent/>}/>

                    <Route path="courses/create" element={<AdminCreateCourse/>}/>
                    <Route path="courses/:id" element={<AdminEditCourse/>}/>
                    <Route path="courses" element={<AdminCourses/>}/>

                    <Route path="guests" element={<AdminGuests/>}/>
                    <Route path="guests/create" element={<AdminCreateGuest/>}/>
                    <Route path="guests/:id" element={<AdminEditGuest/>}/>
                </Route>

                <Route path="events" element={<Events/>}/>
                <Route path="events/:eventId" element={<EventDetail/>}/>

                <Route path="course" element={<RequireAuth onlyAdmin={false}/>}>
                    <Route path=":courseId" element={<Course/>}/>
                    <Route path=":courseId/:stepId" element={<Course/>}/>
                    <Route element={<RequireAuth onlyAdmin={true}/>}>
                        <Route path=":courseId/:stepId/edit" element={<Course edit={true}/>}/>
                        <Route path=":courseId/edit" element={<Course edit={true}/>}/>
                    </Route>
                </Route>

                <Route path="*" element={<NotFound/>}/>
            </Routes>
            <ToastContainer
                position="bottom-center"
                autoClose={20000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
            />
        </Suspense>
    );
}

export default App;
