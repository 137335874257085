import { Navigate } from "react-router-dom";
import {useAppSelector} from "../store";

const Home = () => {
  const menuType = useAppSelector((state) => state.appState.menuType);

  return  menuType === 'main' ? <Navigate replace={true} to="/welcome" /> : <Navigate replace={true} to={`/pages/${menuType}`} />
}

export default Home;
