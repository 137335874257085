import {FunctionComponent} from "react";
import Button from "../../../../../components/Forms/Button";
import {useTranslation} from "react-i18next";
import {useResendVerificationEmailMutation} from "../../../../../api/user/api";
import {toast} from "react-toastify";

interface Props {
  token: string | null
}

const ResendDialog: FunctionComponent<Props> = ({token}) => {
  const {t} = useTranslation();
  const [resend] = useResendVerificationEmailMutation();

  const handleResend = () => {
    if (token) {
      toast.promise(async () => {
        await resend(token).unwrap()
      }, {
        pending: t('forms.sections.resend.pending'),
        error: t('forms.sections.resend.error'),
        success: t('forms.sections.resend.success'),
      })
    }
  }

  return token ? (
    <div className="py-4 px-8 bg-sky-200 my-4 rounded">
      <span className="-mr-1">{t('forms.sections.resend.Title')}</span>
      <Button
        color='link'
        size="sm"
        onClick={handleResend}
      >
        {t('forms.actions.Resend')}
      </Button>
    </div>
  ) : null
}

export default ResendDialog
