import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {axiosBaseQuery} from "../index";
import {MenuType, Tag, UploadResponse, Contact, Paginated, AdminListParams, SuccessResponse} from "../../types";
import { AdminContactEditData } from "../../admin/pages/Guests/Edit/components/EditContactForm";


export const AdminApi = createApi({
  reducerPath: 'admin',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['MenuType', 'Tag', 'Guests'],
  endpoints: (build) => ({
    menuTypes: build.query<MenuType[], null | undefined>({
      query: () => ({url: `/menu-types`, method: 'get'})
    }),
    tags: build.query<Tag[], null | undefined>({
      query: () => ({url: `/tags`, method: 'get'})
    }),
    guestsList: build.query<Paginated<Contact>, AdminListParams>({
      query: ({search, page = 1, size = 10}) => ({url: `/admin/contacts?page=${page}&size=${size}&search=${search}`, method: 'get'}),
      providesTags: ['Guests']
    }),
    guestDetail: build.query<Contact, number>({
      query: (id) => ({url: `/admin/contacts/${id}`, method: 'get'}),
      providesTags: ['Guests']
    }),
    guestCreate: build.mutation<Contact, AdminContactEditData>({
      query:  (data) => ({url: `/admin/contacts`, method: 'post', data}),
      invalidatesTags: ['Guests']
    }),
    guestDelete: build.mutation<SuccessResponse, { id: number }>({
      query: ({id}) => ({url: `/admin/contacts/${id}`, method: 'delete'}),
      invalidatesTags: ['Guests']
    }),
    guestUpdate: build.mutation<Contact, { data: AdminContactEditData, id: number }>({
      query: ({id, data}) => ({url: `/admin/contacts/${id}`, method: 'put', data}),
      invalidatesTags: ['Guests']
    }),
    uploadFile: build.mutation<UploadResponse, Blob>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file)
        return ({url: '/file/upload', method: 'post', data: formData})
      }
    })
  })
})

export const  {
  useMenuTypesQuery,
  useTagsQuery,
  useGuestsListQuery,
  useGuestDetailQuery,
  useGuestCreateMutation,
  useGuestUpdateMutation,
  useGuestDeleteMutation,
  useUploadFileMutation,
} = AdminApi
