import {isRejectedWithValue, Middleware} from "@reduxjs/toolkit";
import {resetAppState} from "../app-slice";
import {RootState} from "../index";


export const UnauthenticatedMiddleware: Middleware = ({dispatch, getState}) => (next) => (action) => {
  const user = (getState() as RootState).appState.user;
  if (isRejectedWithValue(action) && action.payload.status === 403 && !user) {
    dispatch(resetAppState(null));
  }

  return next(action);
}
