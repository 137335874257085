import {FunctionComponent} from "react";
import {Page} from "../../../../types";
import {Link, useLocation} from 'react-router-dom'
import {ChevronDoubleRightIcon} from "@heroicons/react/solid";
import Heading from "../../../../components/Heading";

interface Props {
  pages?: Page[] | null
  parent?: Page
}

const SideMenu: FunctionComponent<Props> = ({pages = [], parent}) => {
  const location = useLocation()

  const getMenuItem = (page: Page) => {

    const path = page.slug !== parent?.slug ? `/pages/${parent!.slug}/${page.slug}` : `/pages/${page.slug}`
    const active = location.pathname === path

    return (
      <li key={`side-menu-${page.id}`} className={`${active ? 'text-blue-500' : ''} my-2`}>
        <Link to={path} className="flex">
          <span className="flex-none pr-2"><ChevronDoubleRightIcon className="w-2 h-2 inline-block"/></span>
          <span className="flex-grow">{page.title}</span>
        </Link>
      </li>
    )
  }

  const navigation = pages ? pages.map(getMenuItem) : [];

  if (navigation.length > 0 && parent) {
    navigation.unshift(
      getMenuItem(parent)
    )
  }

  return (
    <div className="px-4 pt-2 pb-12">
      {navigation.length > 0 ? (
        <Heading size="l"><span className="font-bold text-black text-base">{parent?.title}</span></Heading>) : null}
      <ul className="list-none pl-0">
        {navigation}
      </ul>
    </div>
  )
}

export default SideMenu
