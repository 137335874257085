import Heading from "../../../components/Heading";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import {useRegisterUserMutation} from "../../../api/user/api";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import PageLayout from "../../../layout/PageLayout";
import RegistrationForm from "./components/RegistrationForm";
import * as Sentry from "@sentry/react";
import {resolveErrorMessage} from "../../../utils/errors";

export interface RegistrationFormData {
  first_name: string
  last_name: string
  email: string
  phone: string
  street: string
  zip: string
  city: string
  profile_image: File
  birthDate: Date
  password: string
  password_confirmation: string
  nbs_registration_number: string
  'g-recaptcha-response': string | null
}

const Registration = () => {
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const [registerUser, {isLoading}] = useRegisterUserMutation();

  const {t} = useTranslation();
  const navigate = useNavigate();

  const register = async (data: RegistrationFormData, reset: () => void) => {
    if (data['g-recaptcha-response'] !== null && !isLoading) {
      try {
        await registerUser(data).unwrap();
        toast.success(t("messages.RegistrationSuccess"))
        navigate('/login')
      } catch (e: any) {
        const error = e.data ? e.data : e
        Sentry.captureException(error);
        toast.error(resolveErrorMessage(error, t("messages.RegistrationError")))
        reset()
      }
    }
  }

  return (
    <PageLayout imageUrl={imageUrl}>
      <div>
        <Heading>{t('forms.sections.registration.Title')}</Heading>
        <RegistrationForm onSubmit={register} disabled={isLoading} />
      </div>
    </PageLayout>
  )
}

export default Registration
