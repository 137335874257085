import {configureStore} from '@reduxjs/toolkit';
import AppSlice, {AppSliceState} from "./app-slice";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {UnauthenticatedMiddleware} from "./middleware/unauthenticated";
import {PageApi} from "../api/pages/api";
import {UserApi} from "../api/user/api";
import {AdminApi} from "../api/admin/api";
import {EventApi} from "../api/events/api";
import {CourseApi} from "../api/courses/api";
import {UploadApi} from '../api/upload/api';

export const saveState = (state: AppSliceState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};

const store = configureStore({
  reducer: {
    [PageApi.reducerPath]: PageApi.reducer,
    [EventApi.reducerPath]: EventApi.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [AdminApi.reducerPath]: AdminApi.reducer,
    [CourseApi.reducerPath]: CourseApi.reducer,
    [UploadApi.reducerPath]: UploadApi.reducer,
    [AppSlice.name]: AppSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(
      UnauthenticatedMiddleware,
      EventApi.middleware,
      PageApi.middleware,
      UserApi.middleware,
      UploadApi.middleware,
      AdminApi.middleware,
      CourseApi.middleware,
    ),
});

// setupListeners(store.dispatch);
store.subscribe(() => {
  saveState({
    ...store.getState().appState,
  });
});

// Infer the `RootState` and `AppDispatch` types from the index itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
