import {FunctionComponent} from "react";

interface Props {
  imageUrl: string
}

const HeaderImage: FunctionComponent<Props> = ({imageUrl}) => {
  return (
    <div className="h-72 bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${imageUrl})`}} />
  )
}

export default HeaderImage
