import {createApi} from '@reduxjs/toolkit/query/react';
import {AdminPageListParams, Page, Paginated, SuccessResponse} from "../../types";
import {axiosBaseQuery} from "../";
import {PageFormData} from "../../admin/components/PageForm";

export const PageApi = createApi({
  reducerPath: 'pages',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Pages', 'Users'],
  endpoints: (build) => ({
    menuPages: build.query<Page[], string>({
      query: (category: string) => ({url: `/menu-pages/${category}`, method: 'get'}),
      providesTags: ['Pages']
    }),
    pageDetail: build.query<Page, { slug: string }>({
      query: ({slug}) => ({url: `/pages/${slug}`, method: 'get'}),
      providesTags: ['Pages']
    }),
    adminPageDetail: build.query<Page, { slug: string }>({
      query: ({slug}) => ({url: `/admin/pages/${slug}`, method: 'get'}),
      providesTags: ['Pages']
    }),
    adminPages: build.query<Paginated<Page>, AdminPageListParams>({
      query: ({search, page = 1, size = 10, onlyWithoutParent, menuType, order, orderBy}) => ({
        url: `/admin/pages?page=${page}&size=${size}&search=${search}${onlyWithoutParent ? '&onlyWithoutParent=1' : ''}${menuType ? `&menuType=${menuType}` : ''}${!!order ? `&order=${order}` : ''}${!!orderBy ? `&orderBy=${orderBy}` : ''}`,
        method: 'get'
      }),
      providesTags: ['Pages']
    }),
    createPage: build.mutation<SuccessResponse, PageFormData>({
      query: (data) => ({url: `/admin/pages`, method: 'post', data}),
      invalidatesTags: ['Pages']
    }),
    editPage: build.mutation<SuccessResponse, { data: PageFormData, slug: string }>({
      query: ({data, slug}) => ({url: `/admin/pages/${slug}`, method: 'put', data}),
      invalidatesTags: ['Pages']
    }),
    deletePage: build.mutation<SuccessResponse, { slug: string }>({
      query: ({slug}) => ({url: `/admin/pages/${slug}`, method: 'delete'}),
      invalidatesTags: ['Pages']
    }),
  })
});

export const {
  useMenuPagesQuery,
  usePageDetailQuery,
  useAdminPagesQuery,
  useAdminPageDetailQuery,
  useCreatePageMutation,
  useEditPageMutation,
  useDeletePageMutation,
} = PageApi;
