import Copyright from "../Copyright";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store";
import {setMenuType} from "../../store/app-slice";
import {FunctionComponent} from "react";


const Ruka:FunctionComponent<{text: string, handleClick: (type: string) => void, menuType: string, selectedMenuType: string, imgWhite: string, imgBlue: string}> = ({handleClick, text, menuType, selectedMenuType, imgBlue, imgWhite}) => (
  <div className="text-right px-2 ruka">
    <div className="text-center relative cursor-pointer">
      <a
        title={text}
        href="/"
        onClick={(e) => {
          e.preventDefault();
          handleClick(menuType)
        }}>
        <img src={menuType === selectedMenuType ? imgWhite : imgBlue} alt="ruka footer"
             className="duration-300 ease-out visible img-default transaction delay-200"/>
        <img src={menuType === selectedMenuType ? imgBlue : imgWhite} alt="ruka footer"
             className="absolute top-0 left-0 invisible img-top duration-200 ease-in-out"/>
      </a>
    </div>
  </div>
)

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const menuType = useAppSelector((state) => state.appState.menuType);

  const handleClick = (menuType: string) => {
    dispatch(setMenuType(menuType));
    navigate(`/pages/${menuType}`)
  }

  return (
    <div>
      <div
        className="bg-gray-blue-500 angled-before before:-skew-y-3 before:h-full before:bg-gray-blue-500 before:-z-1 angled-after after:-skew-y-1 after:h-2/6 after:bg-blue-500 after:-bottom-8">
        <div className="lg:w-1/2 mx-auto container grid grid-cols-3 pt-4">
          <Ruka text="Som odbornik"
                handleClick={handleClick}
                menuType="pro"
                selectedMenuType={menuType}
                imgBlue="/ruka-1_svetla.svg"
                imgWhite="/ruka-1_biela.svg"
          />
          <Ruka text="Zaujímam sa o&nbsp;financie podľa biblických princípov"
                handleClick={handleClick}
                menuType="interested"
                selectedMenuType={menuType}
                imgBlue="/ruka-2_svetla.svg"
                imgWhite="/ruka-2_biela.svg"
          />
          <Ruka text="Hľadám kresťanského finačného poradcu"
                handleClick={handleClick}
                menuType="help"
                selectedMenuType={menuType}
                imgBlue="/ruka-3_svetla.svg"
                imgWhite="/ruka-3_biela.svg"
          />
        </div>
      </div>
      <Copyright/>
    </div>
  )
}

export default Footer
