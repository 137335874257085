import {Controller} from "react-hook-form";
import DatePicker from "react-datepicker";
import {FunctionComponent} from "react";
import {Control} from "react-hook-form/dist/types/form";
import sk from 'date-fns/locale/sk'
import {resolveValidationMessage} from "../TextInput";

interface Props {
  control: Control<any>
  name: string
  label: string
  required: boolean
  dateFormat?: string
  timeFormat?: string
  value?: Date
}

const Datepicker: FunctionComponent<Props> = (
  {
    control,
    name,
    label,
    required,
    dateFormat = 'dd.LL.yyyy',
    timeFormat,
    value
  }) => {
  return (
    <div>
      <label className="text-gray-600 font-medium">{label} {required ? '*' : ''}</label>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        rules={{required: required}}
        render={({field, fieldState: {error}}) => (
          <div className="py-4 pr-4">
            <DatePicker
              selected={field.value}
              onChange={field.onChange}
              locale={sk}
              dateFormat={dateFormat}
              required={required}
              className='border border-gray-300 py-2 px-3 w-full rounded focus:ring-blue-300 focus:outline-1 disabled:bg-gray-200 disabled:cursor-not-allowed'
              popperPlacement={"top-end"}
              timeFormat={timeFormat}
              showTimeInput={!!timeFormat}
              //@ts-ignore
            />
            {error ?
              (<p className="text-red-500 text-xs italic mt-1">{resolveValidationMessage(error, label, {})}</p>) : null
            }
          </div>
        )}
      />
    </div>
  )
}

export default Datepicker
