import {FC} from "react";

interface Props {
  title: string
  show?: boolean
  hideDivider?: boolean
}

const Section: FC<Props> = ({children, title, show= true, hideDivider = false}) => {
  return show ? (
    <div className="pt-8">
      <h3 className="font-bold text-blue-500 pb-4 uppercase">{title}</h3>
      {children}
      {hideDivider ? null : <div className="border-blue-500 w-8/12 my-12 border-b"/>}
    </div>
  ) : null
}

export default Section



