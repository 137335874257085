import {FunctionComponent} from "react";
import {Link} from 'react-router-dom';

interface Props {
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
  size?: 'xl' | 'base' | 'sm'
  color?: 'primary' | 'secondary' | 'link'
  disabled?: boolean
  linkTo?: string
  className?: string
}

const buttonConfig = {
  "xl": "px-12 py-4",
  "base": "px-4 py-2",
  "sm": "px-2 py-1"
}

const buttonColors = {
  "primary": "bg-blue-500 hover:bg-blue-800 text-white",
  "secondary": "",
  "link": "text-blue-500 hover:text-blue-800"
}

const Button: FunctionComponent<Props> = ({children, onClick, type = "submit", size = "base", disabled = false, className, linkTo, color = "primary"}) => {
  let fontClass = `text-${size}`
  const classes = `${buttonConfig[size]} rounded ${buttonColors[color]} uppercase font-semibold disabled:bg-gray-300 inline-block ${fontClass} ${className}`

  return linkTo ? (
    <Link
      to={linkTo}
      className={classes}
    >
      {children}
    </Link>
  ) : (
    <button
      className={classes}
      onClick={onClick}
      type={type}
      disabled={disabled}>
      {children}
    </button>
  )
}

export default Button
