import React, {FC} from 'react';
import {useAuthUser} from "../components/UserState/hooks";
import {Navigate, Outlet} from "react-router-dom";

export type AuthenticatedRouteProps = {
  onlyPublic?: boolean;
  onlyAdmin?: boolean;
};

const RequireAuth: FC<AuthenticatedRouteProps> =
  ({
     onlyPublic = false,
     onlyAdmin = false,
   }) => {
    const user = useAuthUser();

    if (onlyAdmin && ((user && !user.is_admin) || !user)) {
      return <Navigate to="/login" />
    }

    if (onlyPublic && user) {
      return <Navigate to="/"/>
    }
    if (!onlyPublic && !user) {
      return <Navigate to="/login"/>
    }
    return (
      <div>
        <Outlet />
      </div>
    )
  };

export default RequireAuth;
