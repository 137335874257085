import {useTranslation} from "react-i18next";
import Section from "./components/Section";
import {useAuthUser} from "../../../components/UserState/hooks";
import Heading from "../../../components/Heading";
import {FunctionComponent} from "react";
import PageLayout from "../../../layout/PageLayout";
import Button from "../../../components/Forms/Button";

const ProfileField: FunctionComponent<{ label: string, text?: string | null, className?: string }> = ({
                                                                                                        label,
                                                                                                        text,
                                                                                                        className
                                                                                                      }) => (
  <div className={`py-2 ${className}`}>
    <label className="py-2 inline-block">{label}</label>
    <p className="font-bold">{text}</p>
  </div>
)

const Profile = () => {
  const user = useAuthUser();
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const {t} = useTranslation();

  const enabledRegions = user?.regions ? user.regions.filter((region) => region.enabled) : [];
  const enabledContacts = user?.contracts ? user.contracts.filter((contract) => contract.enabled) : [];
  const enabledSectors = user?.sectors ? user.sectors.filter((sector) => sector.enabled) : [];
  return (
    <PageLayout imageUrl={imageUrl}>
      <div className="mx-auto p-4 py-12 max-w-2xl ">
        <Heading>{t('profile.Profile')}</Heading>
        <Section title={t('profile.Personal')}>
          <div className="grid md:grid-cols-2 grid-cols">
            <div className="col-span-2 lg:col-span-1 py-8">
              <img src={user?.profile_image || '/profile-default-image.png'} alt="profile"
                   className="mx-auto max-h-64 w-auto rounded-full border border-gray-700"/>
            </div>
            <div className="hidden lg:block"></div>
            <ProfileField label={t('fields.FirstName')} text={user?.first_name}/>
            <ProfileField label={t('fields.LastName')} text={user?.last_name}/>
            <ProfileField label={t('fields.Email')} text={user?.email}/>
            <ProfileField label={t('fields.Phone')} text={user?.phone}/>
            <ProfileField label={t('fields.Street')} text={user?.street} className="col-span-2"/>
            <ProfileField label={t('fields.Zip')} text={user?.zip}/>
            <ProfileField label={t('fields.City')} text={user?.city}/>
          </div>
        </Section>
        <Section title={t('profile.Company')} show={!!user?.registration?.number}>
          <div className="grid md:grid-cols-2 grid-cols">
            <ProfileField label={t('fields.CompanyName')} text={user?.registration?.name} className="col-span-2"/>
            <ProfileField label={t('fields.Street')} text={user?.registration?.street} className="col-span2"/>
            <ProfileField label={t('fields.Zip')} text={user?.registration?.zip}/>
            <ProfileField label={t('fields.City')} text={user?.registration?.city}/>
            <ProfileField label={t('fields.Website')} text={user?.registration?.website}/>
            <ProfileField label={t('fields.Description')} text={user?.registration?.description}/>

          </div>
        </Section>
        <Section title={t('profile.Sectors')} show={enabledSectors.length > 0}>
          <ul className="list-disc font-bold pl-4">
            {enabledSectors.map((sector) => (<li key={`sector-${sector.id}`}>{sector.name}</li>))}
          </ul>
        </Section>
        <Section title={t('profile.Contracts')} show={enabledContacts.length > 0}>
          <ul className="list-disc font-bold pl-4">
            {enabledContacts.map((contract) => (<li key={`sector-${contract.id}`}>{contract.name}</li>))}
          </ul>
        </Section>
        <Section title={t('profile.Regions')} show={enabledRegions.length > 0} hideDivider={true}>
          <ul className="list-disc font-bold pl-4">
            {enabledRegions.map((region) => (<li key={`sector-${region.id}`}>{region.name}</li>))}
          </ul>
        </Section>
        <div className="text-center pt-16">
          <Button size="xl" linkTo="/user/profile/edit">{t('forms.actions.Edit')}</Button>
        </div>
      </div>
    </PageLayout>
  )
}

export default Profile
