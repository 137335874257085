import HeaderImage from "../../../components/HeaderImage";
import Heading from "../../../components/Heading";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useResetPasswordMutation} from "../../../api/user/api";
import ResetPasswordForm, {ResetPasswordFormData} from "./components/ResetPasswordForm";
import {useNavigate, useParams} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {resolveErrorMessage} from "../../../utils/errors";


const ResetPassword = () => {
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const {t} = useTranslation();
  const [resetPassword, {isLoading}] = useResetPasswordMutation();
  const navigate = useNavigate()
  const params = useParams()

  const handleSubmitResetPassword = async (data: ResetPasswordFormData) => {
    if (!isLoading) {
      try {
        await resetPassword({
          ...data,
          token: params.token || '',
          email: params.email || ''
        }).unwrap();
        toast.success(t("messages.ResetPasswordSuccess"))
        navigate('/login')
      } catch (e: any) {
        const error = e.data ? e.data : e
        Sentry.captureException(error);
        toast.error(resolveErrorMessage(error, t("messages.ResetPasswordError")))
      }
    }
  }
  return (
    <div>
      <HeaderImage imageUrl={imageUrl}/>
      <div className="mx-auto p-4 py-12 max-w-2xl ">
        <Heading>{t('forms.sections.resetPassword.Title')}</Heading>
        <ResetPasswordForm onSubmit={handleSubmitResetPassword} disabled={isLoading} />
      </div>
    </div>
  )
}

export default ResetPassword
