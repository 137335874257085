import Heading from "../../../components/Heading";
import {useTranslation} from "react-i18next";
import {useChangePasswordMutation} from "../../../api/user/api";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import ChangePasswordForm, {ChangePasswordFormData} from "./ChangePasswordForm";
import PageLayout from "../../../layout/PageLayout";
import * as Sentry from "@sentry/react";
import {resolveErrorMessage} from "../../../utils/errors";


const ChangePassword = () => {
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const {t} = useTranslation();
  const [changePassword, {isLoading}] = useChangePasswordMutation();
  const navigate = useNavigate();

  const handleSubmitChangePassword = async (data: ChangePasswordFormData) => {
    if (!isLoading) {
      try {
        await changePassword(data).unwrap();
        toast.success(t("messages.ResetPasswordSuccess"))
        navigate('/login')
      } catch (e: any) {
        const error = e.data ? e.data : e
        Sentry.captureException(error);
        toast.error(resolveErrorMessage(error, t("messages.ResetPasswordError")))
      }
    }
  }

  return (
    <PageLayout imageUrl={imageUrl}>
      <div className="p-4 py-12 max-w-2xl">
        <Heading>{t('forms.sections.changePassword.Title')}</Heading>
        <ChangePasswordForm onSubmit={handleSubmitChangePassword} disabled={isLoading} />
      </div>
    </PageLayout>
  )
}

export default ChangePassword
