import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './store';
import {BrowserRouter as Router} from "react-router-dom";
import UserState from "./components/UserState/UserState";
import './lang';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

Sentry.init({
  dsn: "https://785cd02607ee4c9c9d6314c0ac329a3c@o254716.ingest.sentry.io/6133071",
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <UserState>
          <App/>
        </UserState>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
