import PageLayout from "../../layout/PageLayout";
import Heading from "../../components/Heading";
import {useTranslation} from "react-i18next";
import {CONTACT_EMAIL, RECAPTCHA_KEY} from "../../utils/constants";
import TextInput, {resolveValidationMessage} from "../../components/Forms/TextInput";
import {useForm} from "react-hook-form";
import Button from "../../components/Forms/Button";
import {FunctionComponent, useRef, useState} from "react";
import {ConcatFormData} from "./types";
import {useSendContactMessageMutation} from "../../api/user/api";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/react";
import ReCAPTCHA from "react-google-recaptcha";


const ContactForm: FunctionComponent<{onSubmit: (data: ConcatFormData) => Promise<void>, disabled: boolean}> = ({disabled, onSubmit}) => {
  const {t} = useTranslation();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<null | string>(null);
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset
  } = useForm<ConcatFormData>({reValidateMode: 'onChange'});

  const handleSend = handleSubmit((data) => onSubmit({
    ...data,
    'g-recaptcha-response': recaptchaToken
  }).then(() => {
    reset({})
    recaptchaRef.current && recaptchaRef.current.reset()
  }))

  return (
    <form className="py-4" onSubmit={handleSend}>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <TextInput label={t('fields.Name')} name="name" register={register} options={{required: true}}
                   disabled={disabled}/>
        <TextInput label={t('fields.Email')} name="email" register={register} options={{required: true}}
                   disabled={disabled}/>
      </div>
      <label className="text-gray-600 font-medium">{t('fields.Message')} *</label>
      <div className="pr-4 py-4">
        <textarea
          {...register('message', {
            required: true
          })}
          disabled={disabled}
          rows={10}
          className={`border border-gray-300 py-2 px-3 w-full rounded focus:ring-blue-300 disabled:bg-gray-200 disabled:cursor-not-allowed focus:outline-1 ${errors.message ? 'border-red-300' : ''}`}
        />
        {errors.message ?
          (<p
            className="text-red-500 text-xs italic mt-1">{resolveValidationMessage(errors.message, t('fields.Message'), {})}</p>) : null
        }
      </div>
      <div className="pt-2 pb-8">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={RECAPTCHA_KEY}
          onChange={setRecaptchaToken}
        />
      </div>
      <div className="text-right pr-4">
        <Button size="xl" disabled={disabled}>{t('forms.actions.Send')}</Button>
      </div>
    </form>
  )
}

const Contact = () => {
  const {t} = useTranslation();
  const [sendMessage, {isLoading}] = useSendContactMessageMutation();

  const handleSendMessage = async (data: ConcatFormData) => {
    try {
      await sendMessage(data).unwrap()
      toast.success(t('messages.SendMessage'))
    } catch (e: any) {
      const error = e.data ? e.data : e
      Sentry.captureException(error);
      toast.error(t("errors.SendMessageFailed"))
    }
  }

  return (
    <PageLayout>
      <Heading>{t('contact.Title')}</Heading>
      <section>
        <h3 className="font-bold"> Biblia a financie</h3>
        <p>Štúrova 22</p>
        <p>04001 Košice</p>

        <p className="mt-4">IČO: 52 367 703</p>
        <p>Reg.číslo: VVS/1-900/90-56447</p>
        <p>Účet : Fio banka SK38 8330 0000 0028 0163 7799</p>
      </section>
      <div className="border-blue-500 border-b w-full my-12"/>
      <p>
        Nenašli ste na stránke odpovede na vaše otázky?
      </p>
      <p>
        Sme tu pre vás, kontaktujte nás cez tento kontaktný formulár
        alebo e-mailom na <a href={`mailto:${CONTACT_EMAIL}`} className="text-blue-500">{CONTACT_EMAIL}</a>.
      </p>
      <ContactForm onSubmit={(data) => handleSendMessage(data)} disabled={isLoading}/>

    </PageLayout>
  )
}

export default Contact
