import { FC, useState } from "react";
import { Course, CourseChapter, CourseStep } from "../../../types";
import Loader from "../../../components/Loader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Collapse, Divider, IconButton, List, ListItemIcon } from "@mui/material";
import { Add, CheckCircleOutline, CreateOutlined, DeleteOutline, ExpandLess, ExpandMore } from "@mui/icons-material";
import ListItemButton from "@mui/material/ListItemButton";
import ChapterFormDialog, { ChapterFormData } from "../ChapterFormDialog";
import DeleteConfirmDialog from "../DeleteConfirmDialog";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../utils/errors";
import {
  useCreateCourseChapterMutation, useCreateCourseStepMutation,
  useDeleteCourseChapterMutation, useDeleteCourseStepMutation,
  useEditCourseChapterMutation, useEditCourseStepMutation
} from "../../../api/courses/api";
import { useTranslation } from "react-i18next";
import StepFormDialog, { StepFormData } from "../StepFormDialog";
import { useNavigate } from "react-router-dom";

interface Props {
  course: Course
  edit?: boolean
  isLoading: boolean
  selectedStep?: CourseStep
}

interface ChapterActions {
  modify: boolean
  remove: boolean
  selectedChapter: CourseChapter | null
}

interface StepActions {
  modify: boolean
  remove: boolean
  selectedStep: CourseStep | null,
  parentChapter: CourseChapter | null
}

interface CollapseStateWrapper {
  [id: number]: boolean
}

const SideMenu: FC<Props> = ({ course, edit, isLoading, selectedStep }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [chapterActions, setChapterAction] = useState<ChapterActions>({
    modify: false,
    remove: false,
    selectedChapter: null
  });
  const [stepActions, setStepAction] = useState<StepActions>({
    modify: false,
    remove: false,
    selectedStep: null,
    parentChapter: null
  });
  const [collapseState, setCollapseState] = useState<CollapseStateWrapper>({});
  const [createChapter] = useCreateCourseChapterMutation();
  const [editChapter] = useEditCourseChapterMutation();
  const [deleteChapter] = useDeleteCourseChapterMutation();
  const [createStep] = useCreateCourseStepMutation();
  const [editStep] = useEditCourseStepMutation();
  const [deleteStep] = useDeleteCourseStepMutation();

  const saveChapter = (data: ChapterFormData) => {
    if (course?.id) {
      let promise
      if (chapterActions.selectedChapter) {
        promise = editChapter({ data, id: chapterActions.selectedChapter.id })
      } else {
        promise = createChapter({ data, id: course.id })
      }
      promise.then((resp: any) => {
        if (resp?.error) {
          Sentry.captureException(resp.error);
          toast.error(resolveErrorMessage(resp.error, t("errors.CreateChapter")))
        } else {
          toast.success(t("messages.CreateChapter"))
        }
      })
    }
    return Promise.resolve();
  }

  const saveStep = (data: StepFormData, chapter: CourseChapter | null) => {
    if (chapter?.id) {
      let promise
      if (stepActions.selectedStep) {
        promise = editStep({
          data,
          id: stepActions.selectedStep.id,
          course_chapter_id: stepActions.selectedStep.course_chapter_id
        })
      } else {
        promise = createStep({ data, id: chapter.id })
      }
      promise.then((resp: any) => {
        if (resp?.error) {
          Sentry.captureException(resp.error);
          toast.error(resolveErrorMessage(resp.error, t("errors.CreateStep")))
        } else {
          toast.success(t("messages.CreateStep"))
        }
      })
    }
    return Promise.resolve();
  }

  return (
    <>
      <List>
        <Loader isLoading={isLoading} />
        {course.chapters?.map(chapter => (
          <>
            <ListItemButton key={`chapter-${chapter.id}`} onClick={() => {
              setCollapseState({
                ...collapseState,
                [chapter.id]: !collapseState[chapter.id]
              })
            }}>
              <ListItemIcon>
                {!collapseState[chapter.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
              <ListItemText primary={chapter.name} />
              {edit && <>
                <ListItemIcon>
                  <IconButton onClick={(e) => {
                    e.stopPropagation()
                    setChapterAction({
                      modify: true,
                      remove: false,
                      selectedChapter: chapter
                    })
                  }}>
                    <CreateOutlined />
                  </IconButton>
                </ListItemIcon>
                <ListItemIcon>
                  <IconButton onClick={(e) => {
                    e.stopPropagation()
                    setChapterAction({
                      modify: false,
                      remove: true,
                      selectedChapter: chapter
                    })
                  }} color="error">
                    <DeleteOutline />
                  </IconButton>
                </ListItemIcon>
              </>
              }
            </ListItemButton>
            <Divider />
            <Collapse in={!collapseState[chapter.id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {chapter?.steps?.map((step) => (
                  <>
                    <ListItemButton sx={{ pl: 4 }} key={`step-${step.id}`} onClick={() => navigate(`/course/${course.id}/${step.id}`)} selected={selectedStep && selectedStep.id === step.id}>
                      <ListItemText primary={step.name} />
                      {step.isComplete && (<ListItemIcon>
                        <CheckCircleOutline color="success" />
                      </ListItemIcon>)
                      }
                      {edit && <>
                        <ListItemIcon>
                          <IconButton onClick={(e) => {
                            e.stopPropagation()
                            setStepAction({
                              modify: false,
                              remove: true,
                              selectedStep: step,
                              parentChapter: chapter
                            })
                          }} color="error">
                            <DeleteOutline />
                          </IconButton>
                        </ListItemIcon>
                      </>
                      }
                    </ListItemButton>
                    <Divider light />
                  </>
                ))}
                {edit && <><ListItemButton sx={{ pl: 4 }} onClick={() => setStepAction({
                  modify: true,
                  remove: false,
                  selectedStep: null,
                  parentChapter: chapter
                })}>
                  <ListItemIcon>
                    <Add />
                  </ListItemIcon>
                  <ListItemText primary={t('courses.forms.addStep.Add')} />
                </ListItemButton>
                  <Divider light />
                </>
                }
              </List>
            </Collapse>
          </>
        ))}
        {edit && <ListItem disablePadding>
          <ListItemButton onClick={() => setChapterAction({
            modify: true,
            remove: false,
            selectedChapter: null
          })}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary={t('courses.forms.addChapter.Add')} />
          </ListItemButton>
        </ListItem>}
      </List>

      {
        chapterActions.modify ? <ChapterFormDialog open={true} data={chapterActions.selectedChapter} onClose={() => {
          setChapterAction({
            modify: false,
            remove: false,
            selectedChapter: null
          });
        }} onSave={saveChapter} /> : null
      }

      {
        stepActions.modify ? <StepFormDialog open={true} data={stepActions.selectedStep} onClose={() => {
          setStepAction({
            modify: false,
            remove: false,
            selectedStep: null,
            parentChapter: null
          });
        }} onSave={(data) => saveStep(data, stepActions.parentChapter)} /> : null
      }

      {
        chapterActions.remove && chapterActions.selectedChapter ? <DeleteConfirmDialog open={true} onConfirm={() => {
          if (chapterActions.selectedChapter) {
            deleteChapter(chapterActions.selectedChapter.id).then((resp: any) => {
              if (resp?.error) {
                Sentry.captureException(resp.error);
                toast.error(resolveErrorMessage(resp.error, t("errors.DeleteChapter")))
              } else {
                toast.success(t("messages.DeleteChapter"))
              }
            })
          }
        }} onClose={() => setChapterAction({
          modify: false,
          remove: false,
          selectedChapter: null
        })} /> : null
      }

      {
        stepActions.remove && stepActions.selectedStep ? <DeleteConfirmDialog open={true} onConfirm={() => {
          if (stepActions.selectedStep) {
            deleteStep(stepActions.selectedStep.id).then((resp: any) => {
              if (resp?.error) {
                Sentry.captureException(resp.error);
                toast.error(resolveErrorMessage(resp.error, t("errors.DeleteStep")))
              } else {
                toast.success(t("messages.DeleteStep"))
              }
            })
          }
        }} onClose={() => setStepAction({
          modify: false,
          remove: false,
          selectedStep: null,
          parentChapter: null
        })} /> : null
      }
    </>
  )
}


export default SideMenu
