import {FunctionComponent, useRef, useState} from "react";
import TextInput from "../../../../../components/Forms/TextInput";
import {Link} from "react-router-dom";
import Button from "../../../../../components/Forms/Button";
import {useForm} from "react-hook-form";
import {LoginData} from "../../../../../api/user/api";
import {useTranslation} from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA_KEY} from "../../../../../utils/constants";

export interface Props {
  onSubmit: (data: LoginData, reset: () => void) => void
  disabled: boolean
}

const LoginForm: FunctionComponent<Props> = ({onSubmit, disabled}) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const {register, handleSubmit, formState: {errors}} = useForm<LoginData>();
  const {t} = useTranslation();
  const [recaptchaToken, setRecaptchaToken] = useState<null | string>(null);

  const login = handleSubmit((data,) => {
    onSubmit({
      ...data,
      'g-recaptcha-response': recaptchaToken
    }, () => recaptchaRef.current && recaptchaRef.current.reset())
  });

  return (
    <form onSubmit={login} className="text-center">
      <TextInput
        label={t('fields.Email')}
        register={register}
        name="email"
        options={{required: true}}
        error={errors.email}/>

      <TextInput
        label={t('fields.Password')}
        register={register}
        name="password"
        type="password"
        options={{required: true, minLength: 6}}
        error={errors.password}/>

      <div className="grid grid-cols md:grid-cols-2">
        <div className="text-left pt-2">
          <Link to="/forgot-password"
                className="text-blue-500 hover:text-blue-800">{t('forms.actions.ForgotPassword')}</Link>
        </div>
        <div className="pt-2 pb-8">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            onChange={setRecaptchaToken}
          />
        </div>
      </div>
      <Button disabled={disabled}> {t('forms.actions.Login')} </Button>
      <Button className="ml-4" linkTo="/registration" color="link"> {t('forms.actions.Register')} </Button>
    </form>
  )
}

export default LoginForm
