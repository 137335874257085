import {UseFormRegister} from "react-hook-form/dist/types/form";
import {FunctionComponent, ReactElement} from "react";
import {FieldError} from "react-hook-form/dist/types/errors";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import {resolveValidationMessage} from "../TextInput";

interface Props {
  label: string | ReactElement
  messageLabel?: string
  name: string
  register: UseFormRegister<any>
  options: RegisterOptions
  defaultValue?: string
  error?: FieldError
  placement?: 'top' | 'bottom' | 'center'
}

const placements = {
  "top": "place-content-top pt-2",
  "bottom": "place-content-bottom",
  "center": "place-content-center"
}

const CheckboxInput: FunctionComponent<Props> = ({register, options, name, messageLabel, label, error, defaultValue, placement = "center"}) => {

  return (
    <div>
      <div className="flex py-4 pr-4">
        <div className={`grid ${placements[placement]}`}>
          <input
            className={`border border-gray-300 py-2 px-2 mr-4 focus:ring-0 rounded ${error ? 'border-red-300' : ''}`}
            {...register(name, options)}
            defaultValue={defaultValue}
            type="checkbox"
          />
        </div>
        <div className="flex-1">
          <label className="text-gray-600 text-xs">{label}&nbsp;{options.required ? '*' : ''}</label>
          {error ?
            (<p className="text-red-500 text-xs italic mt-1">{resolveValidationMessage(error, messageLabel || label.toString(), options)}</p>) : null
          }
        </div>
      </div>
    </div>
  )
}


export default CheckboxInput
