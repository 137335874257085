import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {FC} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import FormInputText from "../../../admin/components/Forms/FormInputText";
import {CourseStep} from "../../../types";

export interface StepFormData {
  name: string
  order: number,
  content: string
}

interface Props {
  open: boolean
  onClose: () => void
  data: CourseStep | null
  onSave: (data: StepFormData) => Promise<void>
}

const StepFormDialog: FC<Props> = ({open, data, onClose, onSave}) => {
  const {t} = useTranslation();
  const {
    handleSubmit,
    control,
  } = useForm<StepFormData>({reValidateMode: 'onChange'});

  const submitForm = handleSubmit(async (formData) => {
    await onSave({
      ...formData,
      content: data?.content || "obsah"
    })
    onClose()
  })

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle id="responsive-dialog-title">
        {t('courses.forms.addStep.Title')}
      </DialogTitle>
      <DialogContent>
        <div className="py-8 px-4">
          <form id="Step-form" onSubmit={submitForm}>
              <Box className="pb-4">
                <FormInputText
                  value={data?.name || ''}
                  label={t('fields.Name')}
                  name="name"
                  control={control}
                  required={true}
                  minLength={3}
                  maxLength={255}/>
              </Box>
              <Box className="pb-4">
                <FormInputText
                  type="number"
                  value={data?.order || ''}
                  label={t('fields.Order')}
                  name="order"
                  control={control}
                  required={true}
                />
              </Box>
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {t('forms.actions.Cancel')}
        </Button>
        <Button onClick={submitForm} autoFocus form="Step-form" type="submit">
          {t('forms.actions.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default StepFormDialog
