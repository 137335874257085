import {FunctionComponent, ReactElement} from "react";
import { useGetUploadsQuery } from "../api/upload/api";
import HeaderImage from "../components/HeaderImage";
import Loader from "../components/Loader";

interface Props {
  sideBar?: ReactElement
  imageUrl?: string | null
}

const PageLayout: FunctionComponent<Props> = ({children, imageUrl, sideBar}) => {
  const defaultImage = '/pages/01_o-nas.jpg'
  const categoryType = {type: 'system', category: 'sideimages'}
  const {data: uploads, isFetching: isLoading} = useGetUploadsQuery({search: '', page: 1, size: 999, ...categoryType});

  return (
    <div className="content-bg">
      <HeaderImage imageUrl={imageUrl || defaultImage}/>
      <div className="container mx-auto">
        <div className="grid md:grid-cols-12">
          {sideBar && <div className="block md:hidden bg-sky-20 border-b border-sky-500 border-solid">
            <div className="pt-12 px-4">
              {sideBar}
            </div>
          </div> }
          <div className="md:col-span-8 p-8 bg-white md:py-24 py-12">
            {children}
          </div>
          <div className="block col-span-4 bg-sky-20 border-l border-sky-500 border-solid">
            <div className="py-24 px-4">
              {sideBar}
              <Loader isLoading={isLoading}/>
              {uploads && uploads.data.map((upload) => upload.description.startsWith("https://") ? <a href={upload.description} target="_blank" rel="noreferrer" key={`sideimage-${upload.id}`}><img src={upload.file} alt={upload.description} className="max-w-xs p-2"/></a> : <img key={`sideimage-${upload.id}`} src={upload.file} alt={upload.description} className="max-w-xs p-2"/>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageLayout;
