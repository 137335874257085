import {TextField} from "@mui/material";
import {Controller} from "react-hook-form";
import {TextInputFormProps} from "../types";
import {FC} from "react";
import {resolveValidationMessage} from "../../../../components/Forms/TextInput";

const FormInputText: FC<TextInputFormProps> = ({name, control, label, value = '', placeholder, options, type, required, maxLength, minLength}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={{required: required, minLength, maxLength, ...options}}
      render={({field, fieldState: {error}}) => (
        <TextField fullWidth onChange={field.onChange} value={field.value} label={label} error={!!error}
                    placeholder={placeholder}
                   type={type} required={required}
                   helperText={error ? resolveValidationMessage(error, label, {minLength, maxLength}) : ''}/>
      )}
    />
  )
};

export default FormInputText
