import React, {FunctionComponent} from "react";
import Loader from "../components/Loader";
import {Disclosure} from '@headlessui/react'
import {MenuIcon, XIcon} from '@heroicons/react/outline'
import Footer from "../components/Footer";
import {Link, useLocation, Outlet} from 'react-router-dom';
import {useAppSelector} from "../store";
import Notifications from "./components/Notifications";
import UserMenu from "./components/UserMenu";
import {useMenuPagesQuery} from "../api/pages/api";
import BiblicalQuote from "../components/BiblicalQuote";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}


const AppLayout: FunctionComponent = ({children}) => {
  const menuType = useAppSelector((state) => state.appState.menuType);
  const location = useLocation();

  const {data: menuPages, isFetching: isLoading} = useMenuPagesQuery(menuType === "main" ? "pro" : menuType);
  const navigation = menuPages ? menuPages.map((page) => {
    const path = `/pages/${page.slug}`
    const active = location.pathname.indexOf(path) !== - 1
    return (
      <Link
        key={`menu-page-${page.id}`}
        to={path}
        data-testid="main-menu-item"
        className={classNames(
          active ? 'bg-gray-blue-900 text-white' : 'text-gray-300 hover:bg-gray-blue-700 hover:text-white',
          'block px-3 py-2 mt-4 rounded-md text-base font-medium'
        )}
        aria-current={active ? 'page' : undefined}
      >
        {page.title}
      </Link>
    )
  }) : []

  const kontaktLink = (
    <Link
      key={`menu-page-contact`}
      to={`/kontakt`}
      data-testid="main-menu-item"
      className={classNames(
        location.pathname === '/kontakt' ? 'bg-gray-blue-900 text-white' : 'text-gray-300 hover:bg-gray-blue-700 hover:text-white',
        'block px-3 py-2 mt-4 rounded-md text-base font-medium'
      )}
      aria-current={undefined}
    >
      Kontakt
    </Link>
  )

  return (
      <div>
        <BiblicalQuote />
        <Disclosure as="nav"
                    className="bg-gray-blue-500 angled-before before:-skew-y-2 before:h-screen before:bg-gray-blue-500 before:-z-1 pt-8">
          {({open}) => (
            <>
              <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-blue-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true"/>
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex-shrink-0 flex items-center">
                      <Link to="/welcome">
                        <img
                          className="block lg:hidden h-16 pt-1 pb-1 w-auto"
                          src="/logo-dark-small.jpg"
                          alt="Workflow"
                        />
                        <img
                          className="hidden lg:block h-24 w-auto"
                          src="/logo-dark.jpg"
                          alt="Workflow"
                        />
                      </Link>
                    </div>
                    <div className="hidden sm:block sm:ml-6 lg:mt-3.5 mt-1.5">
                      <div className="flex space-x-4">
                        {navigation}
                        {kontaktLink}
                      </div>
                    </div>
                  </div>
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <Notifications/>
                    <UserMenu/>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation}
                  {kontaktLink}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div>
          {isLoading ? <Loader isLoading={true} data-testid="loader"/> : children || <Outlet />}
        </div>
        <Link to="/pages/ochrana-osobnych-udajov" className="fixed bottom-10 right-10 cursor-pointer z-9999">
          <img src="/gdpr.png" className="w-18 h-18" alt="gdpr icon"/>
        </Link>
        <Footer/>
      </div>
    )
}

export default AppLayout
