import PageLayout from "../../layout/PageLayout";
import {usePageDetailQuery} from "../../api/pages/api";
import Loader from "../../components/Loader";
import {useParams} from "react-router-dom";
import Heading from "../../components/Heading";
import SideMenu from "./components/SideMenu";



const Page = () => {
  const params = useParams()
  const {data: page, isFetching: isLoading} = usePageDetailQuery({slug: params.slug || ''})
  const parent = page?.parent ? page.parent : page;

  return isLoading ? <Loader /> : (
    <PageLayout imageUrl={parent?.image} sideBar={<SideMenu parent={parent} pages={page?.parent ? page.parent?.children : page?.children} />}>
      <div>
        <Heading>{page?.title}</Heading>
        <div className="content" dangerouslySetInnerHTML={{__html: page?.content || ''}}></div>
      </div>
    </PageLayout>
    )
}

export default Page
