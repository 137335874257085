import {FunctionComponent} from "react";

interface Props {
  size?: "xl" | "l"
}

const marginSizes = {
  "l": "mt-6 mb-8",
  "xl": "mt-10 mb-18"
}

const Heading: FunctionComponent<Props> = ({children, size = "xl"}) => {
  return (
    <div>
      <h2 className="text-4xl text-blue-500 uppercase">{children}</h2>
      <div className={`border border-blue-500 w-12 ${marginSizes[size]}`}/>
    </div>
  )
}

export default Heading
