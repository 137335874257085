import {FunctionComponent} from "react";
import {useNavigate} from "react-router-dom";
import {setMenuType} from "../../../../store/app-slice";
import {useAppDispatch} from "../../../../store";

interface Props {
  image: string
  hoverImage: string
  text: string
  selectedOption: 'pro' | 'help' |'interested'
}

const HandCard: FunctionComponent<Props> = ({text, image, hoverImage, selectedOption}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setMenuType(selectedOption));
    navigate("/")
  }
  return (
    <div className="bg-light-blue-100 rounded p-4 ruka cursor-pointer" onClick={handleClick}>
      <div className="text-center relative">
        <img src={image} alt="ruka3" className="duration-300 ease-out visible img-default transaction delay-200" />
        <img src={hoverImage} alt="ruka3" className="absolute top-0 left-0 invisible img-top duration-200 ease-in-out" />
      </div>
      <p className="text-xl text-center pb-4">{text}</p>
    </div>
  )
}


export default HandCard
