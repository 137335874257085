import {FunctionComponent, useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  CssBaseline, Divider,
  Drawer,
  IconButton, Paper,
  styled,
  ThemeProvider,
  Toolbar,
  Typography,
  useTheme
} from "@mui/material";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {Copyright} from "./AdminLayout";
import {Course, CourseStep} from "../types";
import Loader from "../components/Loader";
import SideMenu from "../courses/components/SideMenu";


const drawerWidth = 350;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
  open?: boolean;
}>(({theme, open}) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

// @ts-ignore
const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

interface Props {
  course?: Course
  selectedStep?: CourseStep
  edit?: boolean
  isLoading: boolean
}

const CourseLayout: FunctionComponent<Props> = ({children, course, isLoading,  edit, selectedStep}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{display: 'flex'}} className="course">
        <CssBaseline/>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <Typography variant="h6" noWrap sx={{flexGrow: 1}} component="div">
              {course?.name}
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{...(open && {display: 'none'})}}
            >
              <MenuIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Main open={open}>
          <DrawerHeader/>
          <Loader isLoading={isLoading}/>
          <Paper className="p-8" >
            {children}
            <Copyright sx={{pt: 4}}/>
          </Paper>
        </Main>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
            </IconButton>
          </DrawerHeader>
          <Divider/>
          {course ? <SideMenu course={course} edit={edit} isLoading={isLoading} selectedStep={selectedStep}/> : null}
        </Drawer>
      </Box>
    </ThemeProvider>
  )
}


export default CourseLayout
